let bb = `18251861864
13915915821
17701589205
17512524858
17301485910
17301480632
18351905101
13915912405
19708446281
17315441213
17826053918
19505167702
13382071508
18052022285
19505178867`


let getPhones = () => {
    console.log(1212)
    console.log(bb)
    let arr_b = bb.split('\n')
    let result_arr = []
    console.log(1215, arr_b)
    for (let b of arr_b) {
        b = b.replace(/\s/g, "");
        result_arr.push(`"${b}"`)
    }
    console.log(734, result_arr.join(','))
    return result_arr.join(',')
}
let arr = getPhones()
export default arr